<!--
	作者：jyl
	时间：2017-07-26
	描述：商户信息管理页面
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.Rule_Name')">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.debtLeaveRuleName"
              placeholder="输入规则名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="规则编码">
            <el-input
              size="12"
              :maxlength="50"
              v-model.trim="formInline.debtLeaveRuleCode"
              placeholder="输入规则编码"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              v-if="$route.meta.authority.button.query"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >

            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="addHandle('a')"
              v-if="$route.meta.authority.button.add"
              >{{ $t('button.addto') }}</el-button
            >
          </el-form-item>
          <!-- <el-button type="primary" v-if='$route.meta.authority == 1' icon="el-icon-plus" @click="$router.push({path: '/addRule'})">{{ $t('button.addto') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 99%; font-size: 12px"
        >
          <!--<el-table-column type="index" :index='indexMethod':label="$t('list.index')" width="70" align='center'></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="140"
            align="center"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.view ||
              $route.meta.authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-show="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-show="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c" v-show="$route.meta.authority.button.view"
                    >查看</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="text" @click="$router.push({path: '/seeRule', query: scope.row})">查看</el-button>
              <el-button type="text" @click="editHandle(scope.row)">{{ $t('button.edit') }}</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "calculateManageConf",
  data() {
    return {
      tenantList: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "debtLeaveRuleName",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "debtLeaveRuleCode",
          label: "规则编码",
          width: "",
        },
        {
          prop: "creatorName",
          label: "创建人",
          width: "",
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
          width: "",
        },
        {
          prop: "description",
          label: "描述",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        debtLeaveRuleName: "",
        debtLeaveRuleCode: "",
        operationId: "",
      },
    };
  },
  methods: {
    handleCommand(cmd, data) {
      if (cmd == "c") {
        this.$router.push({
          path: "/seeArrearsRule",
          query: data,
        });
      }
      if (cmd == "b") {
        this.editHandle(data);
      }
      if (cmd == "a") {
        this.delete(data.debtLeaveRuleId);
      }
    },
    delete(debtLeaveRuleId) {
      this.$axios.post(`/acb/2.0/bacb/debtLeaveRule/delete/${debtLeaveRuleId}`).then((res) => {
        if (res.state == 0) {
          // this.$alert("删除成功");
          this.$message({
            type: "success",
            message: "删除成功！",
          });
          this.searchData();
        } else {
          this.$alert(res.value);
        }
      });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    editHandle(data) {
      this.$router.push({ path: "/addArrearsRule", query: data });
    },
    addHandle(cmd) {
      if (cmd == "a") {
        this.$router.push("/addArrearsRule");
      }
      if (cmd == "b") {
        this.$router.push("/addRule");
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startExpiryDate = dateFormat(data[0], "yyyy-MM-dd");
        let endExpiryDate = dateFormat(data[1], "yyyy-MM-dd");
        this.formInline.startExpiryDate = startExpiryDate;
        this.formInline.endExpiryDate = endExpiryDate;
      } else {
        this.formInline.startExpiryDate = "";
        this.formInline.endExpiryDate = "";
      }
    },
    showDetail(index, obj) {
      this.$router.push({
        path: "/merchantManager-AE",
        query: obj,
      });
    },
    // 管理用户
    managerUser(index, obj) {
      this.$router.push({
        path: "/managerUser",
        query: obj,
      });
    },
    // 搜索
    searchData() {
      this.loading = true;
      //  this.formInline = clearNull(this.formInline);
      this.$axios
        .get("/acb/2.0/bacb/debtLeaveRule/list", {
          data: {
            ...this.formInline,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {},
  created() {
    this.getTenantList();
  },
  activated() {
    this.searchData();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
